<template>
    <CModal scrollable size="xl" class="py-4" :visible="visible" @close="$emit('closed')">
        <CModalHeader>
            <CModalTitle>Document Preview</CModalTitle>
        </CModalHeader>
        <CModalBody class="content-body">
            <iframe v-if="validated" :src="source"></iframe>
        </CModalBody>
    </CModal>
</template>

<script>
import {baseURL, getVerUri} from '@/services/ApiService';

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        v2: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            required: true,
        },
        typeId: {
            type: Number,
            required: true,
        },
        contentType: {
            type: String,
            required: true,
        },
        documentId: {
            type: Number,
            default: null,
        },
    },

    data() {
        return {
            validated: false,
        };
    },

    computed: {
        source() {
            if (this.v2) {
                return baseURL + getVerUri(`${this.type}/${this.typeId}/preview`, 2);
            }

            return (
                baseURL +
                getVerUri('documents/preview') +
                `?type=${this.type}` +
                `&${this.type}_id=${this.typeId}` +
                `&content_type=${this.contentType}` +
                `&requested_document_id=${this.documentId || ''}`
            );
        },
    },

    watch: {
        visible(newVal) {
            if (newVal) {
                this.validated = true;
            }
        },
    }
};
</script>

<style scoped lang="scss">
.content-body {
    height: 100vh;

    iframe {
        height: 95%;
        width: 100%;
    }
}
</style>
